body {
  height: 100%;
  width: 100%;
  position: fixed;
  height: 100vh;
  width: 100vw;
  margin:0;
  padding:0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; /* color of the scroll thumb */
  width: 3px; /* roundness of the scroll thumb */
  border-radius: 16px;
}

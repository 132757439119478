.login {
  background-color: #1a1c1f;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../Assets/Images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ._loginContainer {
    .form-wrapper {
      .header-container {
        display: flex;
        justify-content: center;
        margin-bottom: 3.5rem;
        position: relative;
        height: max-content;
        .logo {
          height: 5.25rem;
          width: 3.563rem;
        }
      }
      .loginwrapper {
        .personal {
          text-align: center;
          color: white;
          font-size: 1rem;
          margin-top: 1.813rem;
          cursor: pointer;
        }
        .learn-more-link {
          color: white;
          font-size: 1rem;
          cursor: pointer;
          position: fixed;
          left: 50%;
          bottom: 2rem;
          transform: translate(-50%, -50%);
          margin: 0 auto;
        }
        .header {
          color: white;
          font-size: 1.25rem;
          margin-left: 1.2rem;
        }
        .form-fail {
          font-size: 0.813rem;
          color: red;
          margin-left: 1.2rem;
          margin-top: 0.3rem;
          display: flex;
          align-items: center;
          .danger-login {
            height: 0.8rem;
            width: 0.8rem;
            margin-right: 0.5rem;
          }
        }
        .margin-top-32 {
          margin-top: 4rem;
        }
        .note-text {
          font-family: "Inter";
          color: #8a8a8a;
          font-size: 0.563rem;
          font-weight: 300;
          font-style: italic;
          margin-top: 0.563rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login {
    ._loginContainer {
      overflow-y: auto;
      width: 90%;
      .form-wrapper {
        .forgetText {
          margin-top: 0.5rem;
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.TriviaPage {
  margin: 0.875rem;
  background-color: white;
  border-radius: 0.5rem;
  height: 400px;
  padding: 0.25rem;
  position: relative;
  font-size: 1rem;
  .question-wrapper {
    min-height: 2.5rem;
    border-radius: 0.5rem;
    background-color: #f4b200;
    color: black;
    align-items: center;
    font-family: "Jockey One";
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.1rem;
  }
  .answers-container {
    margin: auto;
    margin-top: 0.875rem;
    width: 90%;
    .answer-wrapper {
      border-radius: 0.5rem;
      min-height: 3.063rem;
      align-items: center;
      font-family: "Jockey One";
      display: flex;
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: #cc1512;
      color: white;
      margin-bottom: 0.313rem;
      cursor: pointer;
    }
    .selected {
      background-color: #f4b200;
      color: #000;
    }
  }
  .btn-container {
    position: fixed;
    width: 84%;
    display: flex;
    justify-content: space-between;
    bottom: 5rem;
    left: 7.5%;
    .empty-btn {
      height: 2.5rem;
      width: 6.5rem;
    }
    .previous-btn {
      height: 2.5rem;
      background-color: #dcdcdc;
      width: 6.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      font-family: "Jockey One";
      cursor: pointer;
    }
    .next-btn {
      height: 2.5rem;
      background-color: #f4b200;
      width: 6.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Jockey One";
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
}
.TiriviaWelcomePage {
  margin: 0.875rem;
  border-radius: 0.5rem;
  padding: 0.25rem;
  position: relative;
  font-size: 1rem;
  display: flex;
  place-items: center;
  text-align: center;
  background-image: url("../../../Assets/Images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .welcome-content {
    width: 70%;
    margin: auto;
    font-family: "Jockey One";
    .header {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
    .content {
      font-size: 1rem;
      line-height: 1.2rem;
      margin-top: 1.3rem;
    }
    .start-btn {
      color: #fff;
      height: 3rem;
      width: 10rem;
      background-color: #cc1512;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      margin: auto;
      margin-top: 2.5rem;
      cursor: pointer;
    }
  }
}


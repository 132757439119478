.SuccessPage {
  margin: 0.875rem;
  background-color: white;
  border-radius: 0.5rem;
  height: 400px;
  padding: 0.25rem;
  position: relative;
  text-align: center;
  font-family: "Jockey One";
  .image-container {
    width: 100%;
    .mimg-succ {
      width: 100%;
      margin-top: -1.1rem;
    }
  }
  .image-container-web {
    width: 350px;
    margin: auto;
    .img-succ {
      width: 100%;
      margin-top: -1.1rem;
    }
  }
  .error-image-container {
    width: 100%;
    .err-img {
      width: 100%;
      margin-top: -1rem;
    }
  }
  .error-image-container-web {
    width: 350px;
    margin: auto;
    .err-img {
      width: 100%;
      margin-top: -1.1rem;
    }
  }
  .close-icon {
    position: absolute;
    height: 1.938rem;
    width: 1.938rem;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    top: 20px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .close {
      height: 0.688rem;
      width: 0.688rem;
    }
  }
  .congrats-text {
    color: #cc1512;
    font-size: 1.75rem;
  }
  .score-text {
    font-size: 1rem;
    margin-top: 0.8rem;
  }
  .sub-texts {
    margin: auto;
    width: 80%;
    color: #000;
    font-size: 0.8rem;
    margin-top: 0.625rem;
  }
  .score {
    font-size: 1.75rem;
    margin-top: 0.5rem;
  }
  .btn-container-success {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1;
    column-gap: 0.5rem;
    margin-top: 1.8rem;
    .score-btn-container {
      .score-btn {
        width: 9rem;
        height: 2.5rem;
        background-color: #cc1512;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        cursor: pointer;
        color: #fff;
        .score-icon {
          height: 1rem;
          width: 1.25rem;
          margin-left: 1rem;
        }
      }
    }
    .share-btn-container {
      .share-btn {
        width: 9rem;
        height: 2.5rem;
        background-color: #f4f4f4;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        cursor: pointer;
        .share {
          height: 1rem;
          width: 1rem;
          margin-left: 1rem;
        }
      }
      .copied {
        color: #fff;
        font-size: 0.5rem;
        padding: 0.2rem 0.5rem;
        background-color: #acacac7b;
        border-radius: 0.2rem;
        margin-left: 0.5rem;
        font-family: "Inter";
        width: max-content;
        margin: auto;
        margin-top: 10px;
      }
    }
  }

  .succe-lottie {
    position: absolute;
    top: 20%;
  }
}

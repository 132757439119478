.ChatPage {
  background-color: #141819;
  height: 100vh;
  width: 100%;
  position: relative;
  font-family: "Poppins";
  background-image: url("../../Assets/Images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .ChatNavbar {
    padding-top: 1.063rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    display: flex;
    position: static;
    .tab-conatiner {
      width: 100%;
      height: 2.3rem;
      background-color: #3e3e3e;
      border-radius: 0.5rem;
      padding-top: 0.188rem;
      padding-bottom: 0.188rem;
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      display: flex;
      .tab {
        color: #fff;
        width: 100%;
        border-radius: 0.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Jockey One";
        font-weight: 500;
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
        text-transform: uppercase;
        column-gap: 12px;
        cursor: pointer;
        .icon {
          height: 26px;
          width: 18px;
        }
        .icon-quest {
          height: 19px;
          width: 19px;
        }
      }
      .selected {
        background-color: #fff;
        color: #000;
      }
    }
  }
  .ChatContainer {
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
    .disclaimer {
      font-size: 0.6rem;
      color: #acacac;
      background-color: #56565670;
      width: 70%;
      padding: 10px 10px;
      border-radius: 0.25rem;
      margin: auto;
      margin-bottom: 1.5rem;
      text-align: center;
      font-family: "Inter";
      margin-top: 1rem;
    }
    .InlineMessageComponent {
      display: flex;
      width: 100%;
      margin-left: 1rem;
      padding-bottom: 0.1rem;
      transition: all 1s ease-in;
      .avatar {
        height: 1.813rem;
        width: 1.813rem;
        object-fit: cover;
        border-radius: 0.25rem;
      }
      .message-container-wrapper {
        width: 85%;
        margin-bottom: 0.3rem;
        padding-right: 3%;
        position: relative;
        .message-container {
          border-radius: 0.25rem;
          margin-left: 0.438rem;
          padding-left: 0.7rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          background-color: #323232;
          position: relative;
          font-family: "Inter";
          padding-right: 0.5rem;
          font-size: 0.688rem;
          line-height: 1.063rem;
          .sender {
            font-weight: 600;
            font-size: 0.75rem;
            text-transform: capitalize;
          }
        }
        .message-container-invert {
          background-color: #f4b200;
          color: #000;
        }
      }
    }
  }
  .ChatSuggest {
    width: 100%;
    position: fixed;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    column-gap: 0.375rem;
    width: 95%;
    padding-top: 1.3rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: max-content;
    .prompt {
      background-color: #424242;
      color: #fff;
      padding: 6px 10px;
      height: max-content;
      border-radius: 0.313rem;
      cursor: pointer;
      font-size: 0.75rem;
      height: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ChatSuggest::-webkit-scrollbar {
    display: none;
  }
  .ChatInput {
    position: fixed;
    width: 100%;
    bottom: 1.438rem;
    display: flex;
    align-items: center;
    padding-top: 0.625rem;
    .input-container {
      width: 100%;
      height: max-content;
      .input-wrapper {
        background: #242629;
        width: 100%;
        display: flex;
        margin: auto;
        padding-top: 1rem;
        .input-styles {
          min-height: 1.8rem;
          height: auto;
          max-height: 10rem;
          border: none;
          outline: none;
          background: transparent;
          font-family: "Poppins";
          font-size: 0.688rem;
          color: white;
          width: inherit;
          width: 93%;
          resize: none;
          margin-left: 0.625rem;
          margin-right: 0.625rem;
          margin-bottom: 0.813rem;
        }
        textarea::placeholder {
          font-size: 0.688rem;
          color: #8e8e8e;
          font-style: normal;
          font-weight: 400;
        }
        .send-styles {
          width: 5%;
          margin-right: 1rem;
          display: flex;
          justify-content: flex-end;
          align-self: flex-end;
          cursor: pointer;
          .send-icon {
            width: 1rem;
            height: 1rem;
            margin-top: -2.8rem;
          }
        }
      }
    }
  }
}

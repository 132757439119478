.submit-button {
  width: 100%;
  background-color: white;
  height: 2.063rem;
  border-radius: 0.188rem;
  color: black;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 0.688rem;
  .loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #000; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}


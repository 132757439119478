.Home {
  background-color: #1a1c1f;
  height: 100vh;
  width: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../../Assets/Images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .home-container {
    text-align: center;
    .logo-container {
      display: flex;
      justify-content: center;
      margin-bottom: 3.5rem;
      .image {
        height: 2.125rem;
        width: 9.563rem;
      }
    }
    .wave {
      height: 3.375rem;
      width: 3.375rem;
      margin: auto;
      margin-bottom: 1rem;
    }
    .welcome-text {
      color: white;
      font-family: "Inter";
      font-size: 1.5rem;
      font-weight: 500;
    }
    .name {
      color: white;
      font-family: "Inter";
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0.5rem;
    }
  }

  .btn-container {
    position: fixed;
    bottom: 5rem;
    width: 85%;
    .start-text {
      color: white;
      font-family: "Poppins";
      font-size: 1rem;
      margin-bottom: 2rem;
      font-weight: 300;
      text-align: center;
    }
  }
}

.ScoreBoard {
  margin: 0.875rem;
  background-color: white;
  border-radius: 0.5rem;
  min-height: 400px;
  position: relative;
  text-align: center;
  font-family: "Jockey One";
  margin-top: 0;
  .header {
    width: 100%;
    background-color: #f4b200;
    height: 3.125rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    position: relative;
    .close-icon {
      position: absolute;
      height: 1.625rem;
      width: 1.625rem;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      right: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .close {
        height: 0.563rem;
        width: 0.563rem;
      }
    }
  }
  .score-board {
    width: inherit;
    padding: 1.875rem;
    height: 60vh;
    overflow-y: auto;
    .rank-container {
      height: 3.125rem;
      background-color: #cc1512;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      color: white;
      margin-bottom: 5px;
      position: relative;
      flex: 1;
      width: 100%;
      .medal-container {
        width: 20%;
        margin-top: 20px;
        .gold-medal {
          height: 2.875rem;
          width: 2.875rem;
        }
      }
      .score {
        width: 20%;
      }
      .name {
        width: 60%;
        display: flex;
        justify-content: center;
      }
    }
    .rank-silver {
      background-color: #f4b200;
      color: black;
    }
    .rank-bronze {
      background-color: rgba(244, 178, 0, 0.6);
      color: black;
    }
    .rank-4 {
      background-color: rgba(244, 178, 0, 0.4);
      color: black;
    }
    .rank-5 {
      background-color: rgba(244, 178, 0, 0.2);
      color: black;
    }
    .rank-plus {
      background-color: #ececec;
      color: black;
    }
  }
}

.BottomComponent {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.063rem;
  background-color: #313336;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  .text {
    font-family: "Inter";
    font-size: 500;
    color: #8a8a8a;
    font-size: 0.625rem;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 4.8rem;
      height: 0.98rem;
      cursor: pointer;
    }
  }
}
